import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"login-wrapper",attrs:{"fluid":""}},[_c(VContainer,{staticClass:"login"},[_c(VRow,[_c(VCol,[_c(VImg,{attrs:{"src":require("../assets/logo.png"),"height":"10vh","max-height":"200px","contain":""}})],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{staticClass:"login-btn",attrs:{"block":"","color":"#5bc0de","dark":"","height":"60px"},on:{"click":function($event){return _vm.$loginTeamNote()}}},[_c('div',{staticClass:"login-sso-text"},[_vm._v(" "+_vm._s(_vm.$t('login.login_with_teamnote'))+" ")]),_c('div',{staticClass:"staff-only-text"},[_vm._v(" "+_vm._s(_vm.$t('login.staff_only'))+" ")])])],1)],1),(!_vm.showLocalLogin)?_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.showLocalLogin = true}}},[_vm._v("Login with local account ")])],1)],1):_vm._e(),(_vm.showLocalLogin)?_c('div',[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VTextField,{attrs:{"label":_vm.$t('login.username')},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1)],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VTextField,{attrs:{"label":_vm.$t('login.password'),"type":"password"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.nextStep.apply(null, arguments)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"block":"","color":"primary","dark":""},on:{"click":_vm.nextStep}},[_vm._v(" "+_vm._s(_vm.$t('login.title'))+" ")])],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }